.App {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}

h1 {
  font-family: NeueMontreal;
  font-size: 9vw;
  text-transform: uppercase;
  color: #c7c7c7;
  margin: 0;
  letter-spacing: 5px;
}

.About {
  margin-bottom: 0rem;
}

a {
  color: black;
}